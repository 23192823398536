
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useToast } from "vue-toastification";
import { ref } from "yup";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Multiselect from "@vueform/multiselect";
import { checkAuth, isContain } from "@/core/config/custom";

export default defineComponent({
  name: "kt-widget-11",
  components: {
    Field,
    Loading,
    Multiselect,
  },
  props: {
    widgetClasses: String,
  },

  data() {
    return {
      tableData: [],
      listBank: [],
      listArea: [],
      paging: {
        page: 0,
        totalPages: 0,
        size: 10,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },

      branchName: "",
      idBank: "",
      idArea: "",
      cekArea: "",

      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,
      isShow: true,

      showFilter: false,
      timer: 0,
    };
  },

  beforeMount() {
    const store = useStore();

    store.dispatch(Actions.VERIFY_AUTH_BANK);
    this.isShow = !checkAuth(
      JSON.parse(localStorage.getItem(`user_account`)!).role,
      [`MARKETING_LEADER`, `MARKETING_MEMBER`]
    );

    this.idBank = JSON.parse(localStorage.getItem("idBankSelected")!);

    this.idArea = JSON.parse(localStorage.getItem("idAreaSelected")!);

    this.getListBank();

    if (this.idBank && this.idArea) {
      this.cekArea = "1";
      this.getListArea();
      this.getData(this.paging.size, this.paging.page);
    }

    setCurrentPageBreadcrumbs("Cabang", ["Data"]);
  },

  computed: {
    to() {
      let highBound = this.from + this.paging.size;
      if (this.paging.totalElements < highBound) {
        highBound = this.paging.totalElements;
      }
      return highBound;
    },
    from() {
      return this.paging.size * this.paging.page;
    },
  },

  methods: {
    getData(size, page) {
      this.isLoading = true;

      localStorage.setItem("idBankSelected", JSON.stringify(this.idBank));
      localStorage.setItem("idAreaSelected", JSON.stringify(this.idArea));

      const toast = useToast();

      let search = "?";
      if (this.branchName) {
        search = "?branchName=" + this.branchName + "&";
      } else {
        search;
      }

      ApiService.getWithoutSlug(
        "crmv2/main_bank/bank/office/area/branch/all/" +
          this.idBank +
          "/" +
          this.idArea +
          search +
          "page=" +
          page +
          "&size=" +
          size +
          "&sort=branchName&dir=1"
      )
        .then(({ data }) => {
          this.tableData = data.content;
          this.paging.page = data.page;
          this.paging.size = data.size;
          this.paging.totalElements = data.totalElements;
          this.paging.totalPages = data.totalPages;
          this.isLoading = false;
          this.$forceUpdate();
        })
        .catch((errors) => {
          toast.error(errors.response.data.detail);
          this.isLoading = false;
        });
    },

    getListBank() {
      const toast = useToast();

      ApiService.getWithoutSlug("crmv2/main_bank/bank/office/combo_banks")
        .then(({ data }) => {
          this.listBank = data;
        })
        .catch((errors) => {
          toast.error(errors.response.data.detail);
        });
    },

    getListArea() {
      const toast = useToast();

      ApiService.getWithoutSlug(
        "crmv2/main_bank/bank/office/" + this.idBank + "/combo_areas"
      )
        .then(({ data }) => {
          this.cekArea = "isi";

          this.listArea = data;
        })
        .catch((errors) => {
          this.cekArea = "";

          toast.error(errors.response.data.detail);
        });
    },

    onChangeBank() {
      this.getData(this.paging.size, this.paging.page);
    },

    selectedBank(event) {
      this.tableData = [];
      this.idArea = "";
      if (event) {
        this.idBank = event;
        this.getListArea();
      } else {
        this.idBank = "";
        this.listArea = [];
      }
      this.$forceUpdate();
    },
    selectedArea(event) {
      this.tableData = [];
      if (event) {
        this.idArea = event;
        this.getData(this.paging.size, this.paging.page);
      } else {
        this.idArea = "";
      }
      this.$forceUpdate();
    },

    handleDelete(index, item) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: `Data yang telah dihapus tidak dapat dikembalikan !`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Ya, hapus !",
        cancelButtonText: "Batal",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRow(item);
        }
      });
    },
    deleteRow(item) {
      const toast = useToast();
      this.isLoading = true;

      ApiService.delete(
        "crmv2/main_bank/bank/office/area/branch/" +
          item.bankId +
          "/" +
          this.idArea +
          "/" +
          item.branchId
      )
        .then((res) => {
          toast.success("Hapus Berhasil");

          this.getData(this.paging.size, this.paging.page);
          this.isLoading = false;
        })

        .catch((error) => {
          toast.error("Gagal Hapus");
          this.isLoading = false;
        });
    },

    range(min, max) {
      const arr: any[] = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },

    changePage(item) {
      const page = item - 1;
      this.getData(this.paging.size, page);
    },
    nextPage() {
      const page = this.paging.page + 1;
      this.getData(this.paging.size, page);
    },
    prevPage() {
      const page = this.paging.page - 1;
      this.getData(this.paging.size, page);
    },
    changePageSize(e) {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },

    //event
    eventDelaySearch(e) {
      clearTimeout(this.timer); //this timer 0 - set 0 atau clear jika ada input lagi

      this.timer = setTimeout(() => {
        const page = this.paging.page;
        this.getData(this.paging.size, page);
      }, 1000);
    },
    showHideFilter() {
      this.showFilter = !this.showFilter;
    },
  },
});
